import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';
import LoadingSpinner from "Components/LoadingSpinner";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsTenderPagamento from "Utils/Datagrid/Finanziario/TenderPagamento/TenderPagamento";

// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Line, Bar, Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import EuroIcon from '@material-ui/icons/Euro';
import PieChartIcon from '@material-ui/icons/PieChart';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RestorePageOutlined } from "@material-ui/icons";
import { RouteSharp } from "@mui/icons-material";

import {
  PieIncassoAggregatoOptions,
  PieIncassoAggregatoData,
  LineIncassoAggregatoOptions,
  LineIncassoAggregatoData,
} from "Utils/Grafici/Finanziario/Tender Pagamento/IncassoAggregato";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import {getColors} from "Utils/Funzioni/Grafici";
import DettaglioTenderPagamento from "Pages/Vendite/Finanziario/Tender Pagamento/PopUp/DettaglioTenderPagamento";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Myparticles from "Components/MyParticles";
ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Finanziario",
      href: `#`,
      icon: <EuroIcon fontSize="small" />,
    },
    {
      label: "Tender Pagamento",
      href: `/${idCliente}/Report/Finanziario/TenderPagamento`,
      icon: <PieChartIcon fontSize="small" />,
    },
    
  ];
};

var server = configData.general_api_end_point;

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function TenderPagamento() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [open, setOpen] = React.useState(false);
  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsTenderPagamento);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.FinanziarioTenderPagamento;

  const [tenderGiornalieri, setTenderGiornalieri] = React.useState([]);
  const [tenderSpecificoGiornalieri, setTenderSpecificoGiornalieri] = React.useState(
    []
  );
  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  const [pieIncassoAggregatoData, setPieIncassoAggregatoData] = useState([])
  const [lineIncassoAggregatoData, setLineIncassoAggregatoData] = useState([])
  useEffect(() => {
    let negozi = [];
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let configurazioni = {};
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          if (configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) || configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0){
            configurazioni[negozio.id_negozio]=negozio.configurazione
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
          });
          } 
        });
        setIdNegozi(negozi);
        setFiltroNegozi(negozi[0].chiave);
      }
    });
  }, []);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(
        `${server}/${idCliente}/negozi/aggregati/tender`,
        getAllFilters()
      )
      .then(function (response) {
        let tenders = {};
        let importoTotale = 0
        let tendersGiornalieri = {};
        let key = 0;
        console.log(response.data);
        for (let giorno in response.data){
          importoTotale += response.data[giorno].importo; 
          
          for (const [codiceTender, informazioni] of Object.entries(response.data[giorno].totali_per_tender)) {
            if (!(codiceTender in tendersGiornalieri)) {
              tendersGiornalieri[codiceTender] = [];
            }
            tendersGiornalieri[codiceTender].push({Data: response.data[giorno].data,
              ...informazioni,})
            if (!(codiceTender in tenders)) {
              tenders[codiceTender] = {descrizione:informazioni.Descrizione, Totale:informazioni.Totale};
            }else{
              tenders[codiceTender] = {descrizione:informazioni.Descrizione, Totale:tenders[codiceTender].Totale+informazioni.Totale};
            }
          }
        }
        console.log(tendersGiornalieri)
        let rows= [];
        let colori=[];
        for (const [codiceTender, informazioni] of Object.entries(tenders)){
          rows.push({id:codiceTender,...informazioni, incidenza:parseFloat(informazioni.Totale)/parseFloat(importoTotale)*100});
          
        }
        setTenderGiornalieri(tendersGiornalieri);
        PieIncassoAggregatoData.labels = rows.map((tender) => { return tender.descrizione});
        PieIncassoAggregatoData.datasets[0].data = rows.map((tender) => { return tender.Totale});
        PieIncassoAggregatoData.datasets[0].backgroundColor = getColors(rows.length);
        setPieIncassoAggregatoData(PieIncassoAggregatoData)
        setData(rows);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/negozi/aggregati/tender/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const ScontriniRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar idCliente={idCliente} 
          openVendite={true}
          openFinanziario={true}
          breadcrumbsReference={BreadcrumbsReference(idCliente)}/>
          <LoadingSpinner open={caricamentoDati}/>
          <Container className={classes.container} maxWidth="xl" style={{
          marginTop: '3vw',
          filter: !configurazioneReport.attivo ? 'blur(20px)' : "",
          pointerEvents: !configurazioneReport.attivo ? 'none' : "",
        }}>
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" >Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={12} md={12}>
                        <MultipleSelectCheckmarks
                        label={"Selezione Negozi"}
                        selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                          
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper style={{ marginBottom: "2vh" }}>
              <Tabs
                value={tabSelezionato}
                onChange={(event, newValue) => {
                  setTabSelezionato(newValue);
                }}
                variant="fullWidth"
                indicatorColor="[primary]"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab icon={<TocIcon />} label="Analitico" />
                <Tab icon={<TimelineIcon />} label="Grafici" />
              </Tabs>
            </Paper>
            <React.Fragment>
              {tabSelezionato === 0 ? (
                <Paper>
                  <DataGrid
                    style={{ flexGrow: 1, height: "75vh" }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    getRowId={(row) => row.id}
                    rows={data}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={itIT.props.MuiDataGrid.localeText}
                    loading={caricamentoDati}
                    onRowDoubleClick={(event) => {setOpen(true); setTenderSpecificoGiornalieri(tenderGiornalieri[event.row.id])}}
                  />
                </Paper>
              ) : (
                <Paper>
                  <Grid
                    style={{ flexGrow: 1 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xl={5} md={8}>
                      <Pie data={pieIncassoAggregatoData} />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </React.Fragment>
          </Container>
          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioTenderPagamento
                  data={tenderSpecificoGiornalieri}
                  totale={tenderSpecificoGiornalieri.reduce(
                    (previous, current) => previous.Totale + current.Totale,
                    0
                  )}
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
          <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {ScontriniRendered()} </>;
}

export default TenderPagamento;
