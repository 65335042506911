import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";
import DatePicker from "Components/DatePicker";
import TimePicker from "Components/TimePicker";
import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KitchenIcon from "@mui/icons-material/Kitchen";
import HomeIcon from "@material-ui/icons/Home";
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import co from "Utils/Datagrid/Fasce Orario/Reparto/RepartoFasceOrario";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import {
  getSliceOrari,
  getIntervalliOrari,
  getOrarioDa,
} from "Utils/Funzioni/Tempo";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import LoadingSpinner from "Components/LoadingSpinner";
import Myparticles from "Components/MyParticles";
import columnsBilancePlu from "Utils/Datagrid/BilancePlu/BilancePlu";
import DettaglioBilancePlu from "Pages/Bilance/PLU/PopUp/DettaglioBilancePlu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// Configurazione app
var server = configData.bilance_api_end_point;
const moment = extendMoment(Moment);

// Stile Pagina
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));



function BilancePLU() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const firstIndex = 0;
  const [idNegozi, setIdNegozi] = useState([]);
  const [totale, setTotale] = React.useState(0);
  const [pezzi, setPezzi] = React.useState(0);
  const [peso, setPeso] = React.useState(0);
  const [columns, setColumns] = React.useState(columnsBilancePlu);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [dataAggregati, setDataAggregati] = React.useState([]);
  const [dataDettaglio, setDataDettaglio] = React.useState([]);
  const [dataPlu, setDataPlu] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [repartiPerNegozio, setRepartiPerNegozio] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  const [codiceRepartoGiornalieri, setCodiceRepartoGiornalieri] = React.useState("");
  const [reparti, setReparti] = useState([]);
  const [filtroReparti, setFiltroReparti] = useState([]);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroDataDa, setFiltroDataDa] = useState(
    moment(new Date()).subtract(1, "days")
  );

  const handleFiltroDataDaChange = (value) => {
    setFiltroDataDa(value);
  };

  // Ora Da
  const [filtroOraDa, setFiltroOraDa] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  const handleFiltroOraDaChange = (value) => {
    setFiltroOraDa(value);
  };

  // Data A
  const [filtroDataA, setFiltroDataA] = useState(
    moment(new Date()).subtract(1, "days")
  );
  const handleFiltroDataAChange = (value) => {
    setFiltroDataA(value);
  };

  // Ora Da
  const [filtroOraA, setFiltroOraA] = useState(
    new Date(new Date().setHours(23, 59))
  );
  const handleFiltroOraAChange = (value) => {
    setFiltroOraA(value);
  };

  const BreadcrumbsReference = (idCliente) => {
    return [
      {
        label: "HOME",
        href: `/${idCliente}`,
        icon: <HomeIcon fontSize="small" />,
      },
      {
        label: "Bilance",
        href: `/${idCliente}/Report/FasceOrarie`,
        icon: <AccessTimeIcon fontSize="small" />,
      },
      {
        label: "PLU",
        href: `/${idCliente}/Report/Finanziario/Iva`,
        icon: <KitchenIcon fontSize="small" />,
      },
    ];
  };

  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.idNegozio,
            valore: negozio.nomeNegozio,
          });
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].idNegozio]);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${server}/${idCliente}/reparti`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        let reparti = []
        console.log(response.data);
        response.data.forEach((reparto) => {
          reparti.push({ "chiave": reparto.idDepartment, "valore": reparto.nameDepartment });
        });
        setReparti(reparti)
      }
    });
  }, [filtroNegozi]);


  const requestDati = () => {
    aggiornaCaricamento(true);
    setDataAggregati([]);
    setDataDettaglio([]);
    axios
      .post(`${server}/${idCliente}/negozi/aggregati/reparti/plu`, getAllFilters())
      .then(function (response) {

        console.log(response.data);
        
        setDataAggregati(response.data.aggregato);
        setDataDettaglio(response.data.esploso);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/negozi/aggregati/reparti/plu/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        aggiornaCaricamento(false);
        link.setAttribute("download", "report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(filtroDataDa).format("DD/MM/YYYY")}`,
        oraDa: `${moment(filtroOraDa).format("HH:mm")}`,
        dataA: `${moment(filtroDataA).format("DD/MM/YYYY")}`,
        oraA: `${moment(filtroOraA).format("HH:mm")}`,
        puntiVendita: filtroNegozi,
        reparti: filtroReparti,
        plu: ["Tutti"]
      };
    } catch { }

    console.log(filter);
    return filter;
  };

  const BilancePLURendered = () => {
    if (desktop || tablet)
      return (

        <div className={classes.root}>
          <LoadingSpinner open={caricamentoDati} />
          <NavBar idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)} />
          <Container className={classes.container} maxWidth="xl">
          <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={5} md={5} direction="row">
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          spacing={1}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xl={2} md={2}>
                            Da:
                          </Grid>
                          <Grid item xl={6} md={6}>
                            <DatePicker
                              data={filtroDataDa}
                              handleOnChange={handleFiltroDataDaChange}
                            />
                          </Grid>
                          <Grid item xl={4} md={4}>
                            <TimePicker
                              time={filtroOraDa}
                              handleOnChange={handleFiltroOraDaChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          spacing={1}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xl={2} md={2}>
                            A:
                          </Grid>
                          <Grid item xl={6} md={6}>
                            <DatePicker
                              data={filtroDataA}
                              handleOnChange={handleFiltroDataAChange}
                            />
                          </Grid>
                          <Grid item xl={4} md={4}>
                            <TimePicker
                              time={filtroOraA}
                              handleOnChange={handleFiltroOraAChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xl={7} md={7}>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozi"}
                          selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Reparti"}
                          selezioneMultipla={true}
                          items={reparti}
                          setState={setFiltroReparti}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
              {
                dataAggregati.length != 0 ?
                  Object.entries(dataAggregati).map((reparto, index) => {
                    // if (!filtroReparti.includes(reparto[0].cod_reparto)){return (<div></div>)};
                    const nomeReparto = reparto[0];
                    const datiReparto = reparto[1];
                    return (
                      <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">
                            {nomeReparto}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Paper
                            elevation={6}
                            style={{
                              padding: 11,
                              margin: "auto",
                              marginTop: 13,
                              marginBottom: 13,
                              width: "100%",
                            }}
                          >
                            <DataGrid
                              style={{ flexGrow: 1, height: "58vh" }}
                              components={{
                                Toolbar: CustomToolbar,
                              }}
                              getRowId={(row) => row.plu}
                              rows={datiReparto}
                              columns={columns}
                              disableSelectionOnClick
                              localeText={itIT.props.MuiDataGrid.localeText}
                              loading={caricamentoDati}
                              onRowDoubleClick={(event) => {
                                setOpen(true);
                                setDataPlu(dataDettaglio[event.row.plu]);
                              }}
                            />
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                  : <Paper
                    elevation={6}
                    style={{
                      padding: 11,
                      margin: "auto",
                      marginTop: 13,
                      marginBottom: 13,
                      width: "100%",
                      justifyItems: "center"
                    }}
                  ><Typography variant="h6" style={{ margin: "auto" }}> Nessun record </Typography></Paper>
              }
          </Container>
          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioBilancePlu
                  data={dataPlu} />
              </DialogContent>
            </Dialog>
          </React.Fragment>
          <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };

  return <> {BilancePLURendered()} </>;
}

export default BilancePLU;
