const columnsBilancePlu = [
    
    {
      field: 'plu',
      headerName: 'PLU',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'nome',
      headerName: 'Descrizione',
      width: 350,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'importoTotale',
      headerName: 'Totale',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'unitPrice',
      headerName: 'Peso/Unità',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'pesoTotale',
      headerName: 'Peso Totale',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'pezziTotali',
      headerName: 'Pezzi Totali',
      width: 200,
      editable: false,
      valueFormatter: ({ value }) => parseInt(value),
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nrScontrini',
      headerName: 'Nr. Scontrini',
      width: 200,
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nrBattute',
      headerName: 'Nr. Battute',
      width: 200,
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    }
  ];



export default columnsBilancePlu