import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";
import LoadingSpinner from "Components/LoadingSpinner";
import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsIncassoGiornaliero from "Utils/Datagrid/Incasso/IncassoGiornaliero";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import KitchenIcon from '@mui/icons-material/Kitchen';
import {
  PieIncassoAggregatoData
} from "Utils/Grafici/Reparto/IncassoAggregato";
import {getColors} from "Utils/Funzioni/Grafici";
import DettaglioIncassoGiornaliero from "Pages/Vendite/Incasso/PopUp/DettaglioIncassoGiornaliero";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Myparticles from "Components/MyParticles";

ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Incassi",
      href: `/${idCliente}/Incassi`,
      icon: <PointOfSaleIcon fontSize="small" />,
    },
    
  ];
};

var server = configData.general_api_end_point;
const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
    
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function IncassoGiornaliero() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsIncassoGiornaliero);
  const [dettaglioIncassi, setDettaglioIncassi] = React.useState([]);
  const [dettaglioIncassiGiornaliero, setDettaglioIncassiGiornaliero] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.IncassoGiornaliero;

  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  const [pieIncassoAggregatoData, setPieIncassoAggregatoData] = useState([])
  const [lineIncassoAggregatoData, setLineIncassoAggregatoData] = useState([])
  useEffect(() => {
    let negozi = [];
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let configurazioni = {};
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          if (configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) || configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0){
            configurazioni[negozio.id_negozio]=negozio.configurazione
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
          });
          } 
        });
        setIdNegozi(negozi);
        setFiltroNegozi(negozi[0].chiave);
      }
    });
  }, []);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(
        `${server}/${idCliente}/negozi/vendite`,
        getAllFilters()
      )
      .then(function (response) {
        console.log(response)
        setData(response.data.aggregato)
        setDettaglioIncassi(response.data.esploso)
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/negozi/vendite/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "incassi.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const IncassoGiornalieroRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)}
          openVendite={true}
          />
          <LoadingSpinner open={caricamentoDati}/>
          <Container className={classes.container} maxWidth="xl" style={{
          marginTop: '3vw',
          filter: !configurazioneReport.attivo ? 'blur(20px)' : "",
          pointerEvents: !configurazioneReport.attivo ? 'none' : "",
        }}>
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" >Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={12} md={12}>
                        <MultipleSelectCheckmarks
                        label={"Selezione Negozi"}
                        selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                         Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                          
                        >
                         Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
             
                <Paper>
                  <DataGrid
                    style={{ flexGrow: 1, height: "75vh" }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    getRowId={(row) => row.data}
                    rows={data}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={itIT.props.MuiDataGrid.localeText}
                    loading={caricamentoDati}
                    onRowDoubleClick={(event) => {setOpen(true); setDettaglioIncassiGiornaliero(dettaglioIncassi[event.row.data])}}
                  />
                </Paper>
          </Container>
          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioIncassoGiornaliero data={dettaglioIncassiGiornaliero} />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {IncassoGiornalieroRendered()} </>;
}

export default IncassoGiornaliero;
