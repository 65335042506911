import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KitchenIcon from "@mui/icons-material/Kitchen";
import HomeIcon from "@material-ui/icons/Home";
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import columnsRepartoFasceOrario from "Utils/Datagrid/Fasce Orario/Reparto/RepartoFasceOrario";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import {
  getSliceOrari,
  getIntervalliOrari,
  getOrarioDa,
} from "Utils/Funzioni/Tempo";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import LoadingSpinner from "Components/LoadingSpinner";
import Myparticles from "Components/MyParticles";
// Configurazione app
var server = configData.general_api_end_point;
const moment = extendMoment(Moment);

// Stile Pagina
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));



function RepartoFasceOrario() {
  // Gestione layout
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Gestione dati da back-end
  const [idNegozi, setIdNegozi] = useState([]);
  const [reparti, setReparti] = useState([]);
  const [columns, _] = React.useState(columnsRepartoFasceOrario);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  const [apriSpinner, setApriSpinner] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.RepartoFasceOrario;

  const BreadcrumbsReference = (idCliente) => {
    return [
      {
        label: "HOME",
        href: `/${idCliente}`,
        icon: <HomeIcon fontSize="small" />,
      },
      {
        label: "Fasce Orario",
        href: `/${idCliente}/Report/FasceOrarie`,
        icon: <AccessTimeIcon fontSize="small" />,
      },
      {
        label: "Reparto",
        href: `/${idCliente}/Report/Finanziario/Iva`,
        icon: <KitchenIcon fontSize="small" />,
      },
    ];
  };
  // FILTRI
  const [filtroReparti, setFiltroReparti] = useState([]);
  const [filtroNegozi, setFiltroNegozi] = useState([]);
  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);
  const [filtroOraDa, setFiltroOraDa] = useState();
  const [filtroOraA, setFiltroOraA] = useState();
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [filtroDurataIntervallo, setFiltroDurataIntervallo] = useState();

  //  Popolamento campi di selezione
  const [intervalli, setIntervalli] = useState(() => getIntervalliOrari());
  const [orariDisponibiliDa, setOrariDisponibiliDa] = useState(() =>
    getOrarioDa()
  );
  const [orariDisponibiliA, setOrariDisponibiliA] = useState([]);

  useEffect(() => {
    let negozi = [];
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let configurazioni = {};
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          if (configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) || configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0){
            configurazioni[negozio.id_negozio]=negozio.configurazione
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
          });
          } 
        });
        setIdNegozi(negozi);
        setFiltroNegozi(negozi[0].chiave);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${server}/${idCliente}/reparti`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        let reparti = []
        console.log(response.data);
        response.data.forEach((reparto) => {
          if (reparto.id_negozio == filtroNegozi) {
            reparti.push({ "chiave": reparto.codice, "valore": reparto.descrizione });
          }
        });
        setReparti(reparti)
      }
    });
  }, [filtroNegozi]);

  useEffect(() => {
    if (filtroDurataIntervallo === undefined) {
      return;
    }
    let sliceOre = getSliceOrari(
      filtroOraDa,
      filtroDurataIntervallo["stepOre"],
      filtroDurataIntervallo["stepMinuti"]
    );
    let sliceOreFormattato = sliceOre.map((sliceOra) => {
      return sliceOra.format("HH:mm");
    });
    setOrariDisponibiliA(sliceOreFormattato);
  }, [filtroDurataIntervallo]);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    setApriSpinner(true)
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(
        `${server}/${idCliente}/scontrini/aggregati/intervalli/reparti`,
        getAllFilters()
      )
      .then(function (response) {
        let reparti = {};
        let counterGiorni;
        console.log(response.data);
        for (const [negozio, datiNegozio] of Object.entries(response.data)) {
          counterGiorni = 0;
          for (const [giorno, datiGiorno] of Object.entries(datiNegozio)) {
            counterGiorni += 1;
            for (const [reparto, datiReparto] of Object.entries(datiGiorno)) {
              if (!(reparto in reparti)) {
                reparti[reparto] = {};
              }
              for (const [fasciaOraria, datiFasciaOraria] of Object.entries(
                datiReparto
              )) {
                console.log(datiFasciaOraria)
                if (!(datiFasciaOraria.inizio_intervallo in reparti[reparto])) {
                  reparti[reparto][datiFasciaOraria.inizio_intervallo] = {
                    ...datiFasciaOraria,
                  };
                  continue;
                }
                reparti[reparto][datiFasciaOraria.inizio_intervallo] = {
                  ...reparti[reparto][datiFasciaOraria.inizio_intervallo],
                  importo_totale_reparto:
                    reparti[reparto][datiFasciaOraria.inizio_intervallo].importo_totale_reparto +
                    datiFasciaOraria.importo_totale_reparto,
                  nr_fidelity_reparto:
                    reparti[reparto][datiFasciaOraria.inizio_intervallo].nr_fidelity_reparto +
                    datiFasciaOraria.nr_fidelity_reparto,
                  nr_pezzi_reparto:
                    reparti[reparto][datiFasciaOraria.inizio_intervallo].nr_pezzi_reparto +
                    datiFasciaOraria.nr_pezzi_reparto,
                  nr_scontrini_reparto:
                    reparti[reparto][datiFasciaOraria.inizio_intervallo].nr_scontrini_reparto +
                    datiFasciaOraria.nr_scontrini_reparto,
                };
              }
            }
          }
        }
        let rows = {};
        for (const [codiceReparto, informazioniReparto] of Object.entries(
          reparti
        )) {
          rows[codiceReparto] = Object.entries(informazioniReparto).map(
            (fasciaOraria, index) => {
              console.log(fasciaOraria)
              return ({
                ...fasciaOraria[1],
                importo_totale_giorno_reparto:
                  fasciaOraria[1].importo_totale_reparto / counterGiorni,
                nr_pezzi_giorno_reparto: fasciaOraria[1].nr_pezzi_reparto / counterGiorni,
                nr_fidelity_giorno_reparto: fasciaOraria[1].nr_fidelity_reparto / counterGiorni,
                nr_scontrini_giorno_reparto: fasciaOraria[1].nr_scontrini_reparto / counterGiorni
              });
            }
          );
        }
        console.log(rows);
        setData(rows);
        aggiornaCaricamento(false);
        setApriSpinner(false)
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
        setApriSpinner(false)
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/scontrini/aggregati/intervalli/reparti/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const scaricaExcelEsploso = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/scontrini/aggregati/intervalli/reparti/dettaglio/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report_esteso.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        oraDa: `${filtroOraDa}:00`,
        oraA: filtroOraA,
        durataIntervallo: filtroDurataIntervallo.chiave,
        puntiVendita: filtroNegozi,
        reparti: filtroReparti
      };
    } catch { }
    console.log(filter);
    return filter;
  };

  const RepartoFasceOrarioRendered = () => {
    if (desktop || tablet)
      return (

        <div className={classes.root}>
          <LoadingSpinner open={caricamentoDati} />
          <NavBar 
          openVendite={true}
          openFasceOrarie={true}
          idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)} />
          <Container className={classes.container} maxWidth="xl" style={{
          marginTop: '3vw',
          filter: !configurazioneReport.attivo ? 'blur(20px)' : "",
          pointerEvents: !configurazioneReport.attivo ? 'none' : "",
        }}>
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozio"}
                          selezioneMultipla={false}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Reparti"}
                          selezioneMultipla={true}
                          items={reparti}
                          setState={setFiltroReparti}
                        />
                      </Grid>
                      <Grid container spacing={2} item xl={12} md={12}>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Dalle Ore
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroOraDa}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroOraDa(event.target.value);
                              }}
                            >
                              {orariDisponibiliDa.map((orario) => {
                                return (
                                  <MenuItem value={orario.valore}>
                                    {orario.chiave}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Durata intervallo
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroDurataIntervallo}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroDurataIntervallo(event.target.value);
                              }}
                            >
                              {intervalli.map((intervallo) => {
                                return (
                                  <MenuItem value={intervallo}>
                                    {intervallo["chiave"]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Alle Ore
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroOraA}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroOraA(event.target.value);
                              }}
                            >
                              {orariDisponibiliA.map((orario) => {
                                return (
                                  <MenuItem value={orario}>{orario}</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          // disabled={!configurazioneReport.visualizzaReportButton.attivo}
                        >
                          {/* {configurazioneReport.visualizzaReportButton.buttonLabel} */}
                          Visualizza
                        </Button>
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          justifyContent="center"
                          spacing={2}
                          style={{ marginTop: 2 }}
                        >
                          <Grid
                            item
                            xl={6}
                            md={6}
                            justifyContent="center"
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                scaricaExcel();
                              }}
                              style={{
                                margin: "auto",
                              }}
                              // disabled={!configurazioneReport.visualizzaReportButton.attivo}
                            >
                              {/* {configurazioneReport.scaricaExcelButton.buttonLabel} */}
                              Scarica Excel
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            md={6}
                            justifyContent="center"
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                scaricaExcelEsploso();
                              }}
                              style={{
                                margin: "auto",
                              }}
                            >
                              Scarica Excel Dettagliato
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper>
              {
                data.length != 0 ?
                  Object.entries(data).map((reparto, index) => {
                    reparto = reparto[1];
                    // if (!filtroReparti.includes(reparto[0].cod_reparto)){return (<div></div>)};
                    return (
                      <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">
                            {reparto[0].cod_reparto}-{reparto[0].descr_reparto}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Paper
                            elevation={6}
                            style={{
                              padding: 11,
                              margin: "auto",
                              marginTop: 13,
                              marginBottom: 13,
                              width: "100%",
                            }}
                          >
                            <DataGrid
                              style={{ flexGrow: 1, height: "58vh" }}
                              components={{
                                Toolbar: CustomToolbar,
                              }}
                              getRowId={(row) => row.fine_intervallo}
                              rows={reparto}
                              columns={columns}
                              disableSelectionOnClick
                              localeText={itIT.props.MuiDataGrid.localeText}
                              loading={caricamentoDati}
                            />
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                  : <Paper
                    elevation={6}
                    style={{
                      padding: 11,
                      margin: "auto",
                      marginTop: 13,
                      marginBottom: 13,
                      width: "100%",
                      justifyItems: "center"
                    }}
                  ><Typography variant="h6" style={{ margin: "auto" }}> Nessun record </Typography></Paper>
              }
            </Paper>
          </Container>
          <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };

  return <> {RepartoFasceOrarioRendered()} </>;
}

export default RepartoFasceOrario;
