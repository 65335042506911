import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Navbar from "Components/NavBar";
import { DataArraySharp } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
// Pilota per API
import configData from "CONFIGURAZIONE.json";
import { useCallback } from "react";
import CardObiettivoSettore from "Components/CardObiettivoSettore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { createContext, useContext } from "react";
import { LicenzaContext } from "Utils/LicenzaContext";
import columnsReparto from "Utils/Datagrid/Home/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import { useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too
import StarIcon from "@material-ui/icons/Star";

var server = configData.bilance_api_end_point;

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
  ];
};
// Definizione stile
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  container: {
    padding: theme.spacing(2),
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  chart: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      height: "50vh",
    },
  },
  chart2: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
      height: "80vh",
    },
  },
}));

function BilanceFasceOrarie() {
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  // const configurazioneReport = licenzaContext.licenza.configurazione.Home;
  let history = useHistory();
  // Stile
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  // Semafori apertura/chiusura grafici
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // Contenitori dei dati
  const [init, setInit] = useState(false);
  const [jwt, setJWT] = useState("");

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
    axios.get(`${server}/jwt`).then((response) => {
      if (response.status == 200) {
        console.log(response.data);

        setJWT(response.data.token)
      }
    });
  }, []);


  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#dad7cd",
        },
      },
      fpsLimit: 24,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#8ecae6",
        },
        links: {
          color: "#004d40",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 1.5,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 140,
        },
        opacity: {
          value: 0.7,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 15 },
        },
      },
      detectRetina: true,
    }),
    [],
  );


  // Render condizionale
  const BilanceFasceOrarieRendered = () => {
    if (desktop | tablet)
      if (init) {
        return (
          <>
          <tableau-viz id="tableauViz"
              style={{ marginTop:"10vh", marginLeft:"5vw", width:"90vw", height:"80vh" }}
              src='https://eu-west-1a.online.tableau.com/t/sistemiposwebdev/views/TestBilData/MainDash'
              token={jwt}>
          </tableau-viz>
          </>
        );
      }
    
  };

  // Render della pagina
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <Navbar
          idCliente={idCliente}
          openVendite={true}
            openFasceOrarie={true}
          breadcrumbsReference={BreadcrumbsReference(idCliente)}
        />
        {
          BilanceFasceOrarieRendered()
        }


      </div> 
    </main>
  );
}

export default BilanceFasceOrarie;
